/* eslint-disable */
export default {
  init() {
    // JavaScript to be fired on the home page

    // When logo is focused on, update hidden gravity field to focused option.
    // This creates a visible reference to the selection rather than text based.
    $('.option-cards .card').on('focus', function(){
      $('.option-cards .card').each(function(){
        $(this).removeClass('picked');
      });
      let optionNumber = $(this).data('count');
      $(this).addClass('picked');
      $('#input_1_4').val(optionNumber).change();
    });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
