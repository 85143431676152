/* eslint-disable */

// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

jQuery.event.special.touchstart = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.touchmove = {
  setup: function( _, ns, handle ) {
      this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.wheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("wheel", handle, { passive: true });
  }
};
jQuery.event.special.mousewheel = {
  setup: function( _, ns, handle ){
      this.addEventListener("mousewheel", handle, { passive: true });
  }
};

/****
  *************
  *************
    Lazy Loading Images, Videos, and iFrames
    for some reason this doesn't work when placed in common.js
  *************
  *************

  * For <img> tags
  *****************

  Add class .lazy and change src to data-src
  <img class="lazy" data-src="/media/homenew.jpg"/>

  * For inline CSS background images
  ************************************

  Add class .lazy to the div
  Remove any style="background-image:url('');background-size:cover;" etc
  Add custom data attributes:

  <div class="lazy" data-bgimage="<?php if($bgGradientOverlay) echo $bgGradientOverlay . ','; ?>url(<?php echo $bgImageURL; ?>)" data-bgsize="cover" data-bgposition="center" data-bgrepeat="no-repeat">

  * For CSS background images defined
  * in CSS files
  ************************************

  Add class .lazy background to div:

  <div class="my-bg-div lazy-background">

  Remove background image from CSS class and add it nested under a .visible class:

  .my-bg-div {
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    &.visible {
      background-image('../../images/myimg.jpg');
    }
  }

  * For iFrames
  ************************************

  Add class .lazy to iframe
  Change src to data-src

  <iframe class="lazy" data-src="https://snazzymaps.com/embed/289457" style="border:none;"></iframe>

  * For Videos
  ************************************

  Add class .lazy to <video> tag
  Change src to data-src in <source> tag

  <video playsinline autoplay muted loop class="lazy d-none d-xl-block">
    <source data-src="/my-video.mp4" type="video/mp4">
  </video>

  * For Sliders
  ************************************

  Make sure Splide Intersection is imported at the top of common.js

  import { Intersection } from '@splidejs/splide-extension-intersection';

  Add the appropriate lazy classes the way you normally would.

  Update your slider syntax to use the Intersection Observer (this example uses a slider with <img data-src="/myimage.jpg"/> tags):

  var logoSliders = document.getElementsByClassName('rpb-logo-slider');
  for (var logoCount = 0; logoCount < logoSliders.length; logoCount++) {
      // mount a new slider with the Intersection observer
      var logoSplide = new Splide(logoSliders[logoCount]).mount({ Intersection });
      // on intersection, set the data-src of the slider img to the src
      logoSplide.on( 'intersection:in', function ( entry ) {
          $(entry.target).find('.logo-banner-img').each(function() {
              var logoAttr = $(this).attr('data-src');
              $(this).attr('src',logoAttr);
          });
      } );
  }
*****/

document.addEventListener('DOMContentLoaded', function () {
  var lazyloadImages = document.querySelectorAll('img.lazy');
  var lazyloadBgImages = document.querySelectorAll('div.lazy, section.lazy');
  var lazyLoadCssBackgrounds = [].slice.call(document.querySelectorAll('.lazy-background'));
  var lazyVideos = [].slice.call(document.querySelectorAll('video.lazy'));
  var lazyIframes = [].slice.call(document.querySelectorAll('iframe.lazy'));

  if ('IntersectionObserver' in window) {
    // Background Images inline css
    var backgroundImageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          // main target
          var mainTarget = entry.target;
          var bgImage = entry.target.getAttribute('data-bgimage');
          if (bgImage) {
            mainTarget.style.backgroundImage = bgImage;
          }
          var bgSize = entry.target.getAttribute('data-bgsize');
          if (bgSize) {
            mainTarget.style.backgroundSize = bgSize;
          }
          var bgPosition = entry.target.getAttribute('data-bgposition');
          if (bgPosition) {
            mainTarget.style.backgroundPosition = bgPosition;
          }
          var bgRepeat = entry.target.getAttribute('data-bgrepeat');
          if (bgRepeat) {
            mainTarget.style.backgroundRepeat = bgRepeat;
          }

          entry.target.classList.remove('lazy');
          backgroundImageObserver.unobserve(entry.target);
        }
      });
    });

    lazyloadBgImages.forEach(function (mainTarget) {
      backgroundImageObserver.observe(mainTarget);
    });

    // Background images in CSS files
    var lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyLoadCssBackgrounds.forEach(function(lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });

    // Img tags
    var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.classList.remove('lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyloadImages.forEach(function (mainTarget) {
      lazyImageObserver.observe(mainTarget);
    });

    // Videos
    var lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(video) {
        if (video.isIntersecting) {
          for (var source in video.target.children) {
            var videoSource = video.target.children[source];
            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
              videoSource.src = videoSource.dataset.src;
            }
          }

          video.target.load();
          video.target.classList.remove('lazy');
          lazyVideoObserver.unobserve(video.target);
        }
      });
    });

    lazyVideos.forEach(function(lazyVideo) {
      lazyVideoObserver.observe(lazyVideo);
    });

    // iFrames
    var lazyIframeObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(iframe) {
        if (iframe.isIntersecting) {
          var iframeSource = iframe.target;
          iframeSource.src = iframeSource.dataset.src;
          iframeSource.classList.remove('lazy');
          lazyIframeObserver.unobserve(iframeSource);
        }
      });
    });

    lazyIframes.forEach(function(lazyIframe) {
      lazyIframeObserver.observe(lazyIframe);
    });
  }
});

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import singleLocation from './routes/location';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // Single location
  singleLocation,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
